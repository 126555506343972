<template>
	<div class="content tech_content">
		<div class="setMenuColor"  data-menu-color="light">
			<div class="tech_baner">
				<div class="container tech_baner__content">
					<div>
						<div class="tech_baner__title">Техноросст</div>
						<div class="tech_baner__subtitle">Интернет-магазин автосервисного и шиномонтажного оборудования</div>
					</div>
					<img :src="require(`@/assets/img_new/technorosst/banner.jpg`)" class="tech_baner__img"  />
				</div>
			</div>
		</div>

		<div class="tech_section greyBg">
			<div class="blockLeftMenu">
				<div
					class="blockLeftMenu__item noBottomPadding blockLeftMenu__item--tech"
				>
					<div class="container">
						<div class="blockLeftMenu__left big">
							<div class="blockLeftMenu__left__content noFix">
								<p>О проекте</p>
								<p class="blockLeftMenu__left__content_subtitle2">
									Техноросст - официальный дилер профессионального оборудования для автосервисов и шиномонтажей. Главный офис компании находится в Казани.
								</p>
								<p class="blockLeftMenu__left__content_subtitle">
									Для создания удобного функционала нужно понять, как пользователь будет переключаться из одного раздела в другой и как будет оформлять заказ. Для этого все страницы сайта были спроектированы в виде интерактивных прототипов.
								</p>
							</div>
						</div>
						<div class="blockLeftMenu__right">
							<div class="mb12"><a href="https://technorosst.com/" target="_blank" class="redLink">technorosst.com</a></div>
							<p class="mb12" style="color: #838A9E">срок разработки: 12 месяцев</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="tech_section">
			<div class="container mb130-90-60">
				<div class="review">
					<div class="review__text">
						Старый сайт не генерировал достаточное количество продаж. Для наших менеджеров сайт — это главный инструмент. Мы постоянно отсылаем к нему клиентов, чтобы показать товар наглядно или рассказать о его особенностях. Поэтому нам важно, чтобы сайт не зависал, хорошо работал и был удобен.”
					</div>
					<div class="user">
						<div class="user__photo" :style="`background-image: url(${require(`@/assets/img_new/technorosst/denis.jpg`)})`"></div>
						<div class="user__content">
							<div class="user__name">
								Алексей Денисов
							</div>
							<div class="user__desc">
								Менеджер проектов Артрокетс
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="blockLeftMenu">
				<div
					class="blockLeftMenu__item"
				>
					<div class="container">
						<div class="blockLeftMenu__left big">
							<div class="blockLeftMenu__left__content noFix">
								<p>Решение</p>
							</div>
						</div>
						<div class="blockLeftMenu__right">
							<p class="tech_subtitle mb60-40-40">Полный редизайн и переработка интернет-магазина для увеличения продаж по всей России. Сохранение фирменного стиля компании.</p>
							<img class="wow fadeIn mb120-90-60" data-wow-duration="1s" :src="require(`@/assets/img_new/technorosst/design1.png`)" alt="" />

							<div class="packsFeatures">
								<div class="packsFeature">
									<div class="packsFeature__title" data-animate-count="78">0</div>
									<div class="packsFeature__text">
										Дизайн-макетов <br class="hide600" />
										разработанно
									</div>
								</div>
								<div class="packsFeature">
									<div class="packsFeature__title" data-animate-count="50 000">0</div>
									<div class="packsFeature__text">
										Страниц добавлено <br class="hide600" />
										в индексацию поисковых систем
									</div>
								</div>
								<div class="packsFeature">
									<div class="packsFeature__title" data-animate-count="x2">0</div>
									<div class="packsFeature__text">
										В 2 раза увеличена
										<br class="hide600" />
										конверсия покупок
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="container">
				<div class="blockLeftMenu__item noBottomPadding blockLeftMenu__item--tech">
					<div class="blockLeftMenu__left  big">
						<div class="blockLeftMenu__left__content noFix">
							<p>Проектирование структуры</p>
							<p class="blockLeftMenu__left__content_subtitle" style="color: #838A9E">
								Для создания удобного функционала нужно понять, как пользователь будет переключаться из одного раздела в другой и как будет оформлять заказ. Для этого все страницы сайта были спроектированы в виде интерактивных прототипов.
							</p>
						</div>
					</div>
				</div>
				<img class="wow fadeIn design2" data-wow-duration="1s" :src="require(`@/assets/img_new/technorosst/design2.png`)" alt="" />
				<p class="design2_text">
					Без проектирования страниц продумать юзабилити сайта невозможно. Используем наработанные решения с созданных нами e-Commerce проектов. Не просто копируем, а транслируем полученный опыт для решения задач, поставленных заказчиком
				</p>
			</div>
		</div>

		<div class="kk_section2 setMenuColor" data-menu-color="light">
			<div class="kk_section2__inner">
				<div class="container">
					<img class="kk_section2__img mb130-90-60 wow fadeIn" data-wow-duration="1s" :src="require(`@/assets/img_new/kk/design1.jpg`)" alt="" />
				</div>
				<div class="blockLeftMenu">
					<div
						class="blockLeftMenu__item"
					>
						<div class="container">
							<div class="blockLeftMenu__left big">
								<div class="blockLeftMenu__left__content noFix">
									<p>О проекте</p>
								</div>
							</div>
							<div class="blockLeftMenu__right">
								<p>Основная идея создания личного кабинета клиента заключается в том, чтобы объединить все  сервисы и услуги компаний в онлайне в одном месте: для пользователя это удобно и привычно. Всю информацию о действующих продуктах можно увидеть онлайн</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="kk_section3 setMenuColor" data-menu-color="dark">
			<div class="container">
				<div class="kk_title">Сервисы страхования</div>
			</div>
			<div class="blockLeftMenu">
				<div
					class="blockLeftMenu__item noBorder noTopPadding"
				>
					<div class="container">
						<div class="blockLeftMenu__left big">
							<div class="blockLeftMenu__left__content">
								<p>Подача заявления «онлайн»</p>
							</div>
						</div>
						<div class="blockLeftMenu__right">
							<p class="mb60-40-40">
								Разработан единый функционал подачи заявлений и обращений. После оформления заявления вся информация о нем доступна пользователю: дата подачи, тип заявления, статус, номер договора,дата обращения и дата ответа,  номер обращения и предпочтительный вариант ответа на обращение.
							</p>
							<img class="wow fadeIn" data-wow-duration="1s" :src="require(`@/assets/img_new/kk/design2.jpg`)" alt="" />
							<!-- <p class="mb60-40-40">
								Заявить о страховом случае можно через специальную форму: для этого необходимо ввести данные договора страхования, тип страхового события и другие соответствующие данные. После отправки заявления в личном кабинете вы сможете отслеживать его статус.
							</p>
							<img class="wow fadeIn mb60-40-40" data-wow-duration="1s" :src="require(`@/assets/img_new/kk/design3.jpg`)" alt="" />
							<p class="mb60-40-40">
								Как только будет принято решение по вашему заявлению вы получите уведомление.
							</p>
							<img class="wow fadeIn" data-wow-duration="1s" :src="require(`@/assets/img_new/kk/design4.jpg`)" alt="" /> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="kk_section4">
			<div class="kk_logo_section_wrap">
				<div class="kk_logo_section">
					<img class="kk_logo_section__logo wow fadeIn" data-wow-duration="1s" :src="require(`@/assets/img_new/kk/grey_logo.png`)" alt="" />
					<div class="blockLeftMenu">
						<div
							class="blockLeftMenu__item noBorder noTopPadding noBottomPadding"
						>
							<div class="container">
								<div class="blockLeftMenu__left big">
									<div class="blockLeftMenu__left__content">
										<p>Внесение страхового взноса по договору</p>
									</div>
								</div>
								<div class="blockLeftMenu__right">
									<p class="mb60-40-40">
										Оплатить услуги можно с помощью QR-кода через систему быстрых платежей либо через сервис сбербанк онлайн. Также договор можно оплатить традиционным способом: банковской картой, банковским переводом, телефонным звонком в контакт-центр, в любом отделении Почты России.
									</p>
									<img class="wow fadeIn" data-wow-duration="1s" :src="require(`@/assets/img_new/kk/design13.png`)" alt="" />
									
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="kk_section4">
			<div class="container">
				<div class="kk_title">Удобно инвестировать</div>
			</div>
			<div class="blockLeftMenu">
				<div
					class="blockLeftMenu__item noBorder noTopPadding"
				>
					<div class="container">
						<div class="blockLeftMenu__left big">
							<div class="blockLeftMenu__left__content noFix">
								<p>Разработан целый раздел отчеты:</p>
							</div>
						</div>
						<div class="blockLeftMenu__right">
							<div class="tar">
								<p class="kk_section3__text1">Благодаря подробным отчетам инвестирование становится максимально прозрачным.
Разработаны графики движения средств на инвестиционном счете и сравнительный график направления инвестирования.</p>
							</div>
						</div>
					</div>
				</div>
				<div class="container">
					<div class="kk_images_list">
						<div class="kk_images_list__col">
							<img class="mb60-40-40 wow fadeIn" data-wow-duration="1s" :src="require(`@/assets/img_new/kk/design9.jpg`)" alt="" />
							<img class="mb60-40-40 wow fadeIn" data-wow-duration="1s" :src="require(`@/assets/img_new/kk/design8.jpg`)" alt="" />
						</div>
						<div class="kk_images_list__col">
							<img class="mb60-40-40 wow fadeIn" data-wow-duration="1s" :src="require(`@/assets/img_new/kk/design10.jpg`)" alt="" />
							<img class="mb60-40-40 wow fadeIn" data-wow-duration="1s" :src="require(`@/assets/img_new/kk/design11.jpg`)" alt="" />
						</div>
					</div>
				</div>
				<div
					class="blockLeftMenu__item noBorder noTopPadding"
				>
					<div class="container">
						<div class="blockLeftMenu__left big hide1100">
							<div class="blockLeftMenu__left__content"></div>
						</div>
						<div class="blockLeftMenu__right">
							<p>
								Есть возможность онлайн скачать письмо о дополнительном доходе по итогам года и справку для налогового вычета, при этом все данные уже будут заполнены автоматически.
								<br><br>
								Состав портфеля показывается в виде таблицы с процентными ставками и диаграммой. 
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="kk_section3">
			<div class="blockLeftMenu">
				<div
					class="blockLeftMenu__item noBorder noTopPadding"
				>
					<div class="container">
						<div class="blockLeftMenu__left big">
							<div class="blockLeftMenu__left__content">
								<p>Подача обращений</p>
							</div>
						</div>
						<div class="blockLeftMenu__right">
							<p class="mb60-40-40">
								Заявить о страховом случае можно через специальную форму: для этого необходимо ввести данные договора страхования, тип страхового события и другие соответствующие данные. После отправки заявления в личном кабинете вы сможете отслеживать его статус.
							</p>
							<img class="mb60-40-40 wow fadeIn" data-wow-duration="1s" :src="require(`@/assets/img_new/kk/design3.jpg`)" alt="" />
							<p>Как только будет принято решение по вашему заявлению вы получите уведомление.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="kk_section7">
			<div class="container">
				<div class="kk_title">Мобильная версия</div>
			</div>
			<div class="container">
				<div class="kk_subtitle">При разработке <span class="redLink">мобильной версии</span> учли особенности платформ: дизайн-макеты полностью адаптированы <span class="redLink">без потери функционала</span>.</div>
			</div>
			<div class="blockLeftMenu__item noBorder noTopPadding whiteBack">
				<div class="container">
					<div class="blockLeftMenu__left big">
						<div class="blockLeftMenu__left__content">
							<p>
								Tablet <br class="hide1200" />
								768 px.
							</p>
						</div>
					</div>
					<div class="blockLeftMenu__right">
						<img
							:src="require(`@/assets/img_new/kk/tablet1.png`)"
							alt=""
							class="idea--image wow fadeIn tablet_img"
							data-wow-duration="1s"
						/>
					</div>
				</div>
			</div>
			<div class="blockLeftMenu__item noBorder noTopPadding whiteBack">
				<div class="container">
					<div class="blockLeftMenu__left big">
						<div class="blockLeftMenu__left__content">
							<p>
								Mobile <br class="hide1200" />
								320 px.
							</p>
						</div>
					</div>
					<div class="blockLeftMenu__right">
						<PhotoScroll
							:data="mobileAdaptive"
							:clickable="mobileAdaptive.clickable"
							class="kk_scroll_slider"
						></PhotoScroll>
					</div>
				</div>
			</div>
		</div> 
		<!-- <div class="kk_section4">
			<div class="blockLeftMenu">
				<div
					class="blockLeftMenu__item noBorder noTopPadding noBottomPadding mb60-40-40"
				>
					<div class="container">
						<div class="blockLeftMenu__left big">
							<div class="blockLeftMenu__left__content">
								<p>График ожидаемой доходности</p>
							</div>
						</div>
						<div class="blockLeftMenu__right">
							<p class="mb60-40-40">
								Благодаря подробным отчетам инвестирование становится максимально прозрачным. Разработаны графики движения средств на инвестиционном счете и сравнительный график направления инвестирования.
								<br><br>
								Оплатить услуги можно с помощью QR-кода через систему быстрых платежей либо через сервис сбербанк онлайн. Также договор можно оплатить традиционным способом: банковской картой, банковским переводом, телефонным звонком в контакт-центр, в любом отделении Почты России.
							</p>
						</div>
					</div>
				</div>
				
				<div
					class="blockLeftMenu__item noBorder noTopPadding noBottomPadding mb60-40-40"
				>
					<div class="container">
						<div class="blockLeftMenu__left big hide1200"></div>
						<div class="blockLeftMenu__right">
							<div class="kk_title2">Просмотр состовляющей части портфеля</div>
							<p>
								Оплатить услуги можно с помощью QR-кода через систему быстрых платежей либо через сервис сбербанк онлайн. Также договор можно оплатить традиционным способом: банковской картой, банковским переводом, телефонным звонком в контакт-центр, в любом отделении Почты России.
							</p>
						</div>
					</div>
				</div>
				
				<div
					class="blockLeftMenu__item noBorder noTopPadding"
				>
					<div class="container">
						<div class="blockLeftMenu__left big hide1200"></div>
						<div class="blockLeftMenu__right">
							<p class="kk_section3__text2 mb60-40-40">
								Оплатить услуги можно с помощью QR-кода через систему быстрых платежей либо через сервис сбербанк онлайн. Также договор можно оплатить традиционным способом: банковской картой, банковским переводом, телефонным звонком в контакт-центр, в любом отделении Почты России.
							</p>
							<img :src="require(`@/assets/img_new/kk/design11.jpg`)" class="wow fadeIn" data-wow-duration="1s" alt="" />
						</div>
					</div>
				</div>

			</div>
		</div>
		<div class="kk_section3">
			<div class="blockLeftMenu">
				<div
					class="blockLeftMenu__item noBorder noTopPadding"
				>
					<div class="container">
						<div class="blockLeftMenu__left big">
							<div class="blockLeftMenu__left__content">
								<p>Внесение страхового взноса по договору</p>
							</div>
						</div>
						<div class="blockLeftMenu__right">
							<p class="mb60-40-40">
								Оплатить услуги можно с помощью QR-кода через систему быстрых платежей либо через сервис сбербанк онлайн. Также договор можно оплатить традиционным способом: банковской картой, банковским переводом, телефонным звонком в контакт-центр, в любом отделении Почты России.
							</p>
							<img class="wow fadeIn mb60-40-40" data-wow-duration="1s" :src="require(`@/assets/img_new/kk/design2.jpg`)" alt="" />
						</div>
					</div>
				</div>
				<div
					class="blockLeftMenu__item noBorder noTopPadding"
				>
					<div class="container">
						<div class="blockLeftMenu__left big">
							<div class="blockLeftMenu__left__content">
								<p>Подача обращений</p>
							</div>
						</div>
						<div class="blockLeftMenu__right">
							<p class="mb60-40-40">
								Личный кабинет клиента страховой компании «КАПИТАЛ LIFE» способствует увеличению лояльности действующих  клиентов и автоматизации процесса оформления страховых полисов и инвестиционных продуктов. В личном кабинете можно увидеть актуальные статусы по всем продуктам.
							</p>
							<img class="mb60-40-40 wow fadeIn" data-wow-duration="1s" :src="require(`@/assets/img_new/kk/design5.jpg`)" alt="" />
							<img :src="require(`@/assets/img_new/kk/design6.jpg`)" alt="" />
						</div>
					</div>
				</div>
				<div
					class="blockLeftMenu__item noBorder noTopPadding"
				>
					<div class="container">
						<div class="blockLeftMenu__left big">
							<div class="blockLeftMenu__left__content">
								<p>Доступные сервисы</p>
							</div>
						</div>
						<div class="blockLeftMenu__right">
							<p class="mb60-40-40">
								Личный кабинет клиента страховой компании «КАПИТАЛ LIFE» способствует увеличению лояльности действующих  клиентов и автоматизации процесса оформления страховых полисов и инвестиционных продуктов. В личном кабинете можно увидеть актуальные статусы по всем продуктам.
							</p>
							<img class="mb60-40-40 wow fadeIn" data-wow-duration="1s" :src="require(`@/assets/img_new/kk/design15.png`)" alt="" />

							<PhotoScroll
								:data="servicesSlider"
								:clickable="servicesSlider.clickable"
								class="kk_scroll_slider kk_service_slider"
							></PhotoScroll>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="kk_section5">
			<div class="container">
				<div class="kk_title mb0">Оплаты и уведомления</div>
			</div>
		</div>

		<div class="kk_section6">
			<div class="blockLeftMenu">
				<div
					class="blockLeftMenu__item noBorder noTopPadding"
				>
					<div class="container">
						<div class="blockLeftMenu__left"></div>
						<div class="blockLeftMenu__right">
							<img class="mb60-40-40 kk_section6__img wow fadeIn" data-wow-duration="1s" :src="require(`@/assets/img_new/kk/design12.png`)" alt="" />
							<p>
								Оплатить услуги можно с помощью QR-кода через систему быстрых платежей либо через сервис сбербанк онлайн. Также договор можно оплатить традиционным способом: банковской картой, банковским переводом, телефонным звонком в контакт-центр, в любом отделении Почты России.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="kk_section7">
			<div class="container">
				<div class="kk_subtitle">Клиент личного кабинета может самостоятельно <span class="redLink">отслеживать статусы своих обращений</span>: при изменении какого-либо статуса клиенту приходит уведомление на почту либо в виде смс сообщения.</div>
			</div>

			<div
				class="blockLeftMenu__item noBorder noTopPadding noBottomPadding"
			>
				<div class="container">
					<div class="blockLeftMenu__left big">
						<div class="blockLeftMenu__left__content">
							<p>Уведомления</p>
						</div>
					</div>
					<div class="blockLeftMenu__right">
						<div class="tar mb60-40-40">
							<p class="kk_section3__text1">Оплатить услуги можно с помощью QR-кода через систему быстрых платежей либо через сервис сбербанк онлайн. Также договор можно оплатить традиционным способом: банковской картой, банковским переводом, телефонным звонком в контакт-центр, в любом отделении Почты России.</p>
						</div>
					</div>
				</div>
			</div>
			<div class="container mb60-40-40">
				<img class="wow fadeIn" data-wow-duration="1s" :src="require(`@/assets/img_new/kk/design14.png`)" alt="" />
			</div>
			<div
				class="blockLeftMenu__item noBorder noTopPadding noBottomPadding mb130-90-40"
			>
				<div class="container">
					<div class="blockLeftMenu__left big hide1200"></div>
					<div class="blockLeftMenu__right">
						Оплатить услуги можно с помощью QR-кода через систему быстрых платежей либо через сервис сбербанк онлайн. Также договор можно оплатить традиционным способом: банковской картой, банковским переводом, телефонным звонком в контакт-центр, в любом отделении Почты России.
					</div>
				</div>
			</div>
		</div> -->

		<div class="kk_section3 mb130-90-40">
			<div class="blockLeftMenu">
				<div
					class="blockLeftMenu__item setMenuColor"
					data-menu-color="dark"
				>
					<div class="container">
						<div class="blockLeftMenu__left big">
							<div class="blockLeftMenu__left__content">
								<p>Технологии</p>
								<p class="blockLeftMenu__left__content_subtitle"><b>Личный кабинет</b> полностью интегрирован с внутренними сервисами компании.</p>
							</div>
						</div>
						<div class="blockLeftMenu__right">
							<div class="kk_section3__features">
								<div class="kk_features">
									<div class="kk_features__item">
										<div class="kk_feature">
											<div class="kk_feature__title">Backend</div>
											<div class="kk_feature__desc">ASP.NET</div>
										</div>
									</div>	
									<div class="kk_features__item">
										<div class="kk_feature">
											<div class="kk_feature__title">Frontend</div>
											<div class="kk_feature__desc">Vue JS</div>
										</div>
									</div>	
									<div class="kk_features__item">
										<div class="kk_feature">
											<div class="kk_feature__title">Swagger</div>
											<div class="kk_feature__desc">Разработали Rest.API и создали спецификацию в сервисе Swagger для дальнейшей интеграции с мобильным приложением.</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="blockLeftMenu white_bg">
			<div
				class="blockLeftMenu__item abdBlock14 setMenuColor"
				data-menu-color="dark"
			>
				<div class="container">
					<div class="blockLeftMenu__left big">
						<div class="blockLeftMenu__left__content">
							<p>Другие проекты</p>
						</div>
					</div>
					<div class="blockLeftMenu__right">
						<Projects
							:data="projectsData"
							:quantity="3"
							:quantityTable="3"
							:quantityMobile="3"
							:showPagination="false"
							:smallElements="true"
						>
						</Projects>
					</div>
				</div>
			</div>
		</div>

		<section
			class="fullPageSectionContainer setMenuColor newForm"
			data-menu-color="light"
		>
			<div class="fullPageSection">
				<div
					class="fullPageSection--back"
					style="background-color: #262E39"
				></div>
				<Feedback :type="2"></Feedback>
			</div>
		</section>
	</div>
</template>

<style lang="sass">
@import '@/assets/new_sass/case/technorosst/Technorosst'
@import '@/assets/sass/WithLeftMenu'
</style>

<script>
import PhotoScroll from "@/components/app/PhotoScroll";
import projectsData from "@/assets/json/projects.json";
import Projects from "@/components/app/Projects";

var leftMenuItems = null;
function leftMenuItemsPosition(){
	leftMenuItems.forEach(item => {
		var parent = item.closest(".blockLeftMenu__left");
		var parentTop = parent.getBoundingClientRect().top + document.documentElement.scrollTop;
		var parentLeft = parent.getBoundingClientRect().left;
		var parentHeight = parent.offsetHeight;
		var parentWidth = parent.offsetWidth;
		var itemTop = item.offsetTop;
		var scrollTop = window.pageYOffset;
		var windowWidth  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

		var top = 28;

		if(windowWidth < 1440){
			top = 70;
		}

		if(scrollTop + item.offsetHeight >= parentTop + parentHeight - top){
			item.style.top = "auto";
			item.style.bottom = "0px";
			item.style.position = "absolute";
			item.style.width = parentWidth + "px";
			item.style.left = "";
		} else if(scrollTop + top < parentTop){
			item.style.top = "";
			item.style.bottom = "";
			item.style.position = "";
			item.style.width = "";
			item.style.left = "";
		} else {
			item.style.top = top + "px";
			item.style.bottom = "auto";
			item.style.position = "fixed";
			item.style.width = parentWidth + "px";
			item.style.left = parentLeft + "px";
		}
	})
}

export default {
	data: () => ({
		projectsData,
		servicesSlider: {
			clickable: true,
			nameType: "big",
			images: [
				{
					image: require("@/assets/img_new/kk/service1.png"),
					fullImage: require("@/assets/img_new/kk/service1.png"),
				},
				{
					image: require("@/assets/img_new/kk/service2.png"),
					fullImage: require("@/assets/img_new/kk/service2.png"),
				},
				{
					image: require("@/assets/img_new/kk/service3.png"),
					fullImage: require("@/assets/img_new/kk/service3.png"),
				},
				{
					image: require("@/assets/img_new/kk/service4.png"),
					fullImage: require("@/assets/img_new/kk/service4.png"),
				},
				{
					image: require("@/assets/img_new/kk/service5.png"),
					fullImage: require("@/assets/img_new/kk/service5.png"),
				},
			],
		},
		paymentSlider: {
			clickable: true,
			nameType: "big",
			images: [
				{
					image: require(`@/assets/img_new/kk/design13.png`),
					fullImage: require(`@/assets/img_new/kk/design13.png`),
				},
				{
					image: require(`@/assets/img_new/kk/design13.png`),
					fullImage: require(`@/assets/img_new/kk/design13.png`),
				},
				{
					image: require(`@/assets/img_new/kk/design13.png`),
					fullImage: require(`@/assets/img_new/kk/design13.png`),
				},
				{
					image: require(`@/assets/img_new/kk/design13.png`),
					fullImage: require(`@/assets/img_new/kk/design13.png`),
				},
				{
					image: require(`@/assets/img_new/kk/design13.png`),
					fullImage: require(`@/assets/img_new/kk/design13.png`),
				},
			],
		},
		mobileAdaptive: {
			clickable: true,
			nameType: "big",
			images: [
				{
					image: require("@/assets/img_new/kk/mobile1.png"),
					fullImage: require("@/assets/img_new/kk/mobile1.png"),
				},
				{
					image: require("@/assets/img_new/kk/mobile2.png"),
					fullImage: require("@/assets/img_new/kk/mobile2.png"),
				},
				{
					image: require("@/assets/img_new/kk/mobile3.png"),
					fullImage: require("@/assets/img_new/kk/mobile3.png"),
				},
				{
					image: require("@/assets/img_new/kk/mobile4.png"),
					fullImage: require("@/assets/img_new/kk/mobile4.png"),
				},
				{
					image: require("@/assets/img_new/kk/mobile4.png"),
					fullImage: require("@/assets/img_new/kk/mobile4.png"),
				},
			],
		},
	}),
	mounted() {
		leftMenuItems = document.querySelectorAll('.blockLeftMenu__left__content');
		window.onscroll = function() {
			leftMenuItemsPosition();
		}
		window.onresize = function() {
			leftMenuItemsPosition();
		}
	},
	components: {
		PhotoScroll,
		Projects,
		Feedback: () => import("@/components/new/Forms/Feedback"),
	},
};
</script>